.select {
    display: grid;
    grid-gap: var(--sSpasingSmall);
}

.select__label {
    color: var(--cTextLight);
    font-size: var(--sFontSmall);
}

.select__wrapper {
}

.select__container {
    position: relative;
}

.select__icon {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: var(--sSpasingSmall);
    padding-right: var(--sSpasingSmall);
    pointer-events: none;
}

.select__select {
    border-radius: var(--radius);
    background-color: var(--cShade);
    padding: var(--sSpasingMedium)
        calc(var(--sSpasingLarge) + var(--sSpasingMedium)) var(--sSpasingMedium)
        var(--sSpasingLarge);
    border: none;
    outline: none;
    cursor: pointer;
    transition-duration: var(--tGeneric);
    transition-timing-function: var(--tfStandart);
    width: 100%;
    box-sizing: border-box;
    font-size: inherit;
    appearance: none;
}

.select__select:global(.focus-visible) {
    box-shadow: var(--focusRing);
}

.select_errors .select__select {
    box-shadow: var(--dangerRing);
}

.select_errors .select__select:global(.focus-visible) {
    box-shadow: var(--focusRing);
}
