.cart {
    min-height: 1px;
    max-height: 100%;
}

@media screen and (max-width: 991px) {
    .cart {
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        border-radius: 0;
        width: 100%;
        z-index: calc(var(--zModal) + 1);
        max-width: 360px;
        transform: translateX(100%);
        transition-duration: var(--tGeneric);
        transition-timing-function: var(--tfStandart);
        background-color: var(--cMainBg);
        display: flex;
        flex-direction: column;
    }

    .cart_open {
        transform: none;
    }
}

.cart__card {
    overflow: auto;
}

@media screen and (max-width: 991px) {
    .cart__card {
        padding-top: 0;
    }
}

.cart__header {
    padding: var(--sSpasingSmall);
}

.cart__curtain {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition-duration: var(--tGeneric);
    transition-timing-function: var(--tfStandart);
    visibility: hidden;
    opacity: 0;
    z-index: var(--zModal);
}

.cart.cart_open + .cart__curtain {
    opacity: 1;
    visibility: visible;
}

@media (min-width: 992px) {
    .cart__curtain {
        display: none;
    }
}

@media screen and (min-width: 992px) {
    .cart__header {
        display: none;
    }
}

.cart__body {
    display: grid;
    grid-gap: var(--sSpasingMedium);
    margin-bottom: var(--sSpasingLarge);
}

.cart__action {
    display: flex;
    justify-content: flex-end;
}

.cart__issue {
    margin-bottom: var(--sSpasingMedium);
}

.cart__successText {
    margin-bottom: var(--sSpasingMedium);
}

.cart__successAction {
    display: flex;
    justify-content: center;
}

.cart__info {
    margin-bottom: var(--sSpasingMedium);
}

.cart__infoRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cart__infoBonus {
    font-weight: bold;
    margin-left: var(--sSpasingMedium);
    white-space: nowrap;
}
