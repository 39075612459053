.title {
    justify-content: space-between;
}

.title,
.title__wing {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-right: calc(-1 * var(--sSpasingLarge));
    margin-bottom: calc(-1 * var(--sSpasingMedium));
}

.title > *,
.title__wing > * {
    margin-right: var(--sSpasingLarge);
    margin-bottom: var(--sSpasingMedium);
}
