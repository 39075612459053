a {
    color: var(--cText);
    outline: none;
}

a:hover,
a.focus-visible {
    text-decoration: none;
}

.h1 {
    margin-top: 0;
    margin-bottom: 0.5em;
    padding-bottom: 0;
    font-size: var(--sFontH1);
}

.h1_noMargin {
    margin: 0;
}

.h2 {
    margin-top: 0;
    margin-bottom: 0.5em;
    padding-bottom: 0;
    font-size: var(--sFontH2);
}

.h2_noMargin {
    margin: 0;
}

.h3 {
    margin-top: 0;
    margin-bottom: 0.5em;
    padding-bottom: 0;
    font-size: var(--sFontH3);
}

.h3_noMargin {
    margin: 0;
}

.text li {
    margin-bottom: 0.3em;
}

.text h3 {
    font-size: var(--sFontH3);
}

.text p {
    margin-top: 0;
}
.text p:last-child {
    margin-bottom: 0;
}
