.editorConfig {
}

.editorConfig__label {
    color: var(--cTextLight);
    font-size: var(--sFontSmall);
    margin-bottom: var(--sSpasingMedium);
}

.editorConfig__groupCard {
    padding: var(--sSpasingMedium);
    margin-bottom: var(--sSpasingLarge);
    background-color: var(--cShade);
    border-radius: var(--radiusSmall);
}

.editorConfig__categorySelect {
    flex-grow: 1;
}

.editorConfig__categoryHead {
    margin-bottom: var(--sSpasingMedium);
}

.editorConfig__dishCard {
    margin-bottom: var(--sSpasingMedium);
    display: flex;
    align-items: flex-start;
}

.editorConfig__dishCardContainer {
    flex-grow: 1;
    margin-left: var(--sSpasingSmall);
    display: grid;
    gap: var(--sSpasingSmall);
}

.editorConfig__priceInput {
    width: 100px;
}

.editorConfig__action {
    display: flex;
    justify-content: center;
    margin-bottom: var(--sSpasingSmall);
}
