.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding-right: 20px;
    box-sizing: border-box;
}

.header__wing {
    display: flex;
    height: 100%;
    align-items: center;
}

.header__wing:last-child {
    display: flex;
    justify-content: flex-end;
}

@media (max-width: 767px) {
    .header__wing {
        flex-basis: 1px;
        flex-grow: 1;
    }
}

@media (min-width: 768px) {
    .header__wing_center {
        display: none;
    }
}

@media (max-width: 767px) {
    .header__wing_center {
        flex-basis: auto;
        flex-grow: 0;
        padding-block: 5px;
        box-sizing: border-box;
    }
    .header__wing_center a,
    .header__wing_center img {
        height: 100%;
    }
}

.header__wingItem {
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;
}

@media (max-width: 767px) {
    .header__wingItem_dev,
    .header__wingItem_desktop {
        display: none;
    }
}

.header__wingItem:not(:first-child) {
    padding-left: 10px;
}

.header__wingItem:not(:last-child) {
    padding-right: 10px;
}

.header__wingItem:not(:last-child):after {
    position: absolute;
    content: '';
    top: 15px;
    bottom: 15px;
    right: 0;
    height: auto;
    width: 1px;
    background-color: var(--cBorder);
}

@media (max-width: 767px) {
    .header__wingItem_mobileLast:not(:first-child) {
        padding-right: 0;
    }
    .header__wingItem_mobileLast:after {
        display: none;
    }
}

@media (max-width: 767px) {
    .header {
        padding-left: 20px;
    }
}

@media (max-width: 767px) {
    .header__reviewAction {
        display: none;
    }
}

@media (min-width: 768px) {
    .header__navAction {
        display: none;
    }
}

@media (max-width: 767px) {
    .header__button {
        display: none;
    }
}

@media (min-width: 768px) {
    .header__button_mobile {
        display: none;
    }
}
