.newOrder {
    min-height: 100%;
    grid-template-rows: auto 1fr;
    box-sizing: border-box;
}

@media (min-width: 992px) {
    .newOrder {
        height: 100%;
        overflow: auto;
    }
}

@media (min-width: 992px) {
    .newOrder__menu {
        min-height: 1px;
        max-height: 100%;
    }
}

@media (min-width: 992px) {
    .newOrder__menuCard {
        overflow: auto;
    }
}

.newOrder__cart {
    min-height: 1px;
    max-height: 100%;
}


@media (max-width: 991px) {
    .newOrder__cart {
    }
}
