.lapList {
}

.lapList__item {
    padding: var(--sSpasingSmall) var(--sSpasingMedium) var(--sSpasingSmall)
        var(--sSpasingLarge);
    background-color: var(--cShade);
    border-radius: var(--radiusSmall);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
