.checkbox {
    position: relative;
}

.checkbox__input {
    position: absolute;
    margin: 0;
    top: 0;
    left: 0;
    cursor: pointer;
    width: 22px;
    height: 22px;
    opacity: 0;
}

.checkbox__labelContainer {
    display: flex;
    cursor: pointer;
}

.checkbox_center .checkbox__labelContainer {
    align-items: center;
}

.checkbox__tick {
    height: 22px;
    width: 22px;
    margin-right: var(--sSpasingMedium);
    margin-left: var(--sSpasingSmall);
    border-radius: var(--radiusSmall);
    border: 1px solid var(--cBorder);
    box-sizing: border-box;
    transition-duration: calc(var(--tGeneric) / 2);
    transition-timing-function: var(--tfStandart);
    position: relative;
    background-color: var(--cMainBg);
    color: var(--cTextInverse);
}

.checkbox_radio .checkbox__tick {
    border-radius: 50%;
}

.checkbox_radio .checkbox__tick::after {
    content: '';
    display: block;
    height: 8px;
    width: 8px;
    background-color: currentColor;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -4px;
    margin-top: -4px;
    border-radius: 50%;
    opacity: 0;
    transform: scale(0.8);
    transition-duration: calc(var(--tGeneric) / 2);
    transition-timing-function: var(--tfStandart);
}

.checkbox__tick svg {
    position: absolute;
    top: 1px;
    left: 1px;
    opacity: 0;
    transform: scale(0.8);
    transition-duration: calc(var(--tGeneric) / 2);
    transition-timing-function: var(--tfStandart);
    fill: currentColor;
}

.checkbox_radio .checkbox__tick svg {
    display: none;
}

.checkbox__label {
    margin-top: 2px;
}

.checkbox__input:checked + .checkbox__labelContainer .checkbox__tick {
    background-color: var(--cThemeLight);
    border-color: var(--cThemeLight);
}

.checkbox__input:checked + .checkbox__labelContainer .checkbox__tick svg {
    opacity: 1;
    transform: none;
}

.checkbox_radio
    .checkbox__input:checked
    + .checkbox__labelContainer
    .checkbox__tick::after {
    opacity: 1;
    transform: none;
}

.checkbox__input:global(.focus-visible)
    + .checkbox__labelContainer
    .checkbox__tick {
    box-shadow: 0 0 0 3px var(--cThemeLight);
    border-color: transparent;
}

.checkbox__input:global(.focus-visible):checked
    + .checkbox__labelContainer
    .checkbox__tick {
    box-shadow: 0 0 0 3px var(--cTheme);
    border-color: var(--cThemeLight);
}
