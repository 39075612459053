.kdsGrid__wrapper {
    height: 100%;
    background: var(--cText);
    display: flex;
    flex-direction: column;
    position: relative;
}

.kdsGrid__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--cMainBg);
    padding: var(--sSpasingSmall) var(--sSpasingLarge) 0;
}

.kdsGrid {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: auto 1fr;
    gap: var(--sSpasingMedium) var(--sSpasingLarge);
    overflow: auto;
    padding: var(--sSpasingSmall) var(--sSpasingLarge) var(--sSpasingLarge);
}

.kdsGrid__subgridTitle {
    color: var(--cMainBg);
    font-size: var(--sFontBig);
}

.kdsGrid__subgridWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.kdsGrid__subgrid {
    display: flex;
    flex-wrap: wrap;
    writing-mode: vertical-lr;
    flex-grow: 1;
    margin-right: calc(-1 * var(--sSpasingMedium));
    margin-bottom: calc(-1 * var(--sSpasingMedium));
}

.kdsGrid__subgrid > * {
    margin-right: var(--sSpasingMedium);
    margin-bottom: var(--sSpasingMedium);
}

.kdsGrid__group {
    writing-mode: horizontal-tb;
    background-color: var(--cMainBg);
    border-radius: var(--radiusSmall);
    padding-top: var(--sSpasingTiny);
    padding-bottom: var(--sSpasingTiny);
}

.kdsGrid__group:nth-child(even) {
    background-color: var(--cShade);
}

.kdsGrid__item {
    display: flex;
    justify-content: space-between;
    padding: 1px var(--sSpasingSmall);
}

.kdsGrid__item:not(:last-child) {
    border-bottom: 1px solid var(--border);
}

.kdsGrid__item > * + * {
    margin-left: var(--sSpasingSmall);
    font-weight: bold;
}
