.input {
    display: grid;
    grid-gap: var(--sSpasingSmall);
    max-width: 100%;
}

.input__label {
    color: var(--cTextLight);
    font-size: var(--sFontSmall);
}

.input__fieldContainer {
    width: 100%;
    min-width: 1px;
    display: flex;
    position: relative;
    border-radius: var(--radius);
    background-color: var(--cShade);
    align-items: center;
    border: 1px solid var(--cShade);
    transition-duration: var(--tGeneric);
    transition-timing-function: var(--tfStandart);
    box-sizing: border-box;
}

.input_disabled .input__fieldContainer {
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 0L0 6' stroke='%23e0e0e0'/%3E%3C/svg%3E");
    background-repeat: repeat;
    background-color: transparent;
    border: 1px solid var(--cShadeActive);
}

.input__fieldContainer > * {
    position: relative;
    z-index: 2;
}

.input__field {
    max-width: 100%;
    box-sizing: border-box;
    background: none;
    border: none;
    font-size: inherit;
    font-family: inherit;
    outline: none;
    border-radius: var(--radius);
    padding: var(--sSpasingMedium) var(--sSpasingLarge);
    transition-duration: var(--tGeneric);
    transition-timing-function: var(--tfStandart);
    flex-grow: 1;
}

.input_disabled .input__field {
    cursor: not-allowed;
    color: var(--cTextLight);
}

.input_textarea .input__field {
    min-height: 100px;
    resize: vertical;
}

.input__fieldFocusRing {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: var(--radius);
    transition-duration: var(--tGeneric);
    transition-timing-function: var(--tfStandart);
    z-index: 1;
}

.input__field:global(.focus-visible) + .input__fieldFocusRing {
    box-shadow: 0 0 0 3px var(--cThemeLight);
}

.input_errors .input__fieldFocusRing {
    box-shadow: var(--dangerRing);
}

.input__fieldTag {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 var(--radiusSmall);
    padding-right: 0;
    box-sizing: border-box;
    text-align: center;
    min-width: 40px;
    font-size: var(--sFontSmall);
    text-transform: uppercase;
    flex-shrink: 0;
    margin-right: calc(-1 * var(--sSpasingSmall));
    z-index: 3;
}

.input_textarea .input__fieldTag {
    max-height: 42px;
    align-self: flex-start;
}

.input__fieldTag span {
    position: relative;
}

.input__fieldTag::before {
    display: block;
    content: '';
    position: absolute;
    top: var(--radiusSmall);
    bottom: var(--radiusSmall);
    left: var(--radiusSmall);
    right: 0;
    border-radius: var(--radiusSmall);
    background-color: var(--cMainBg);
}
