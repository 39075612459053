.orderCard {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.orderCard__id {
    opacity: 0.7;
    font-size: var(--sFontSmall);
    margin-bottom: var(--sSpasingTiny);
    margin-top: calc(-1 * var(--sSpasingTiny));
}

.orderCard__header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: wrap;

    margin-bottom: var(--sSpasingMedium);
}

.orderCard__name {
    margin: 0;
    font-size: var(--sFontBig);
    font-weight: bold;
}

.orderCard__dishes {
    background-color: var(--cShade);
    padding: var(--sSpasingMedium);
    border-radius: var(--radius);
    display: grid;
    grid-gap: var(--sSpasingSmall);
    margin-bottom: var(--sSpasingMedium);
}

.orderCard__comments {
    color: var(--cDanger);
    margin-bottom: var(--sSpasingMedium);
}

.orderCard__prices:not(:last-child) {
    margin-bottom: var(--sSpasingMedium);
}

.orderCard__prices_row {
    display: flex;
    justify-content: flex-end;
    font-size: var(--sFontSmall);
}

.orderCard__footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-grow: 1;
}

.orderCard__actions {
    display: flex;
    justify-content: space-between;
    font-size: var(--sFontSmall);
    flex-grow: 1;
    align-items: flex-end;
}
