.timeBlock {
    display: flex;
    align-items: center;
}

.timeBlock__separator {
    padding: 0 12px;
}

.timeBlock__separator svg {
    display: block;
    fill: currentColor;
}

.timeBlock__block {
    text-align: center;
}

.timeBlock__time {
    font-size: var(--sFontExtraBig);
}

.timeBlock__date {
    font-size: var(--sFontSmall);
}
