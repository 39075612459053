.cartItem {
    background-color: var(--cShade);
    border-radius: var(--radius);
    padding: var(--sSpasingSmall);
}

.cartItem_limit {
    box-shadow: var(--dangerRing);
}

.cartItem__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: var(--sSpasingMedium);
    margin-bottom: var(--sSpasingSmall);
}

.cartItem__list {
    list-style: none;
    padding: 0;
    margin: 0 var(--sSpasingMedium) var(--sSpasingMedium);
    font-size: var(--sFontSmall);
}

.cartItem__listItem {
    display: flex;
    justify-content: space-between;
}

.cartItem__listItemPrice {
    white-space: nowrap;
    padding-left: var(--sSpasingMedium);
}

.cartItem__listItem:not(:last-child) {
    margin-bottom: 4px;
}

.cartItem__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cartItem__counterControls {
    display: flex;
    align-items: center;
}

.cartItem__counter {
    margin: var(--sSpasingSmall);
    font-weight: bold;
}

.cartItem__content {
    display: flex;
    align-items: center;
    font-size: var(--sFontSmall);
}

.cartItem__remove {
    margin-left: calc(-1 * var(--sSpasingMedium));
    margin-top: calc(-1 * var(--sSpasingMedium));
    margin-right: var(--sSpasingMedium);
}

.cartItem__quantityInput {
    appearance: none;
    background: none;
    border: none;
    font: inherit;
    font-weight: bold;
    display: inline-flex;
    padding: 0;
    width: 30px;
    text-align: center;
    outline: none;
    padding-block: var(--sSpasingSmall);
    border-radius: var(--radiusSmall);
}

.cartItem__quantityInput:global(.focus-visible) {
    box-shadow: var(--focusRing);
}

.cartItem__quantityInput::-webkit-outer-spin-button,
.cartItem__quantityInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.cartItem__quantityInput[type='number'] {
    -moz-appearance: textfield;
}
