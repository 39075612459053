.priceTag {
    padding: var(--sSpasingSmall) var(--sSpasingMedium);
    background-color: var(--cThemeLight);
    border-radius: var(--radiusSmall);
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    color: var(--cTextInverse);
}

.priceTag_old {
    text-decoration: line-through;
    color: var(--cText);
}

.priceTag_bold .priceTag__price {
    font-weight: bold;
}

.priceTag_secondary {
    background-color: var(--cShade);
    color: var(--cText);
}

.priceTag_danger {
    background-color: var(--cDanger);
    color: var(--cText);
}

/*.priceTag_noPaddingVertical {*/
/*    padding-top: 0;*/
/*    padding-bottom: 0;*/
/*}*/
