.newOrderHeader__row {
    display: flex;
    justify-content: space-between;
    column-gap: var(--sSpasingLarge);
    row-gap: var(--sSpasingMedium);
    align-items: center;
    flex-wrap: wrap;
}

.newOrderHeader__infoRow {
    display: flex;
    gap: var(--sSpasingMedium) var(--sSpasingLarge);
    align-items: center;
    flex-wrap: wrap;
}

.newOrderHeader__info {
    font-size: var(--sFontSmall);
}

.newOrderHeader__info a {
    color: inherit;
}
