


.orderGrid {
  display: grid;
  grid-gap: var(--sSpasingSmall);
}

@media (min-width: 768px) {
  .orderGrid {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  }
}
