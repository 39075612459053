.orderTable {
    display: flex;
    flex-direction: column;
    height: 100%;
    font-size: var(--sFontSmall);
}

.orderTable__table {
    border-collapse: collapse;
    border-spacing: 0;
}

.orderTable__table tr:not(:first-child) th,
.orderTable__table tr:not(:first-child) td {
    padding-top: 2px;
}

.orderTable__table tr:not(:last-child) th,
.orderTable__table tr:not(:last-child) td {
    padding-bottom: 2px;
}

.orderTable__header {
    text-align: inherit;
    width: 120px;
    border-right: 1px solid var(--cBorder);
}

.orderTable__content {
    padding-left: var(--sSpasingLarge);
}

.orderTable__action {
    flex-grow: 1;
    align-items: flex-end;
    margin-top: var(--sSpasingLarge);
    display: flex;
    justify-content: flex-end;
}

.orderTable__status {
    font-weight: bold;
}

.orderTable__status_accepted {
    color: var(--cSuccess);
}

.orderTable__status_failed {
    color: var(--cDanger);
}
