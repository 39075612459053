.newMenuDishCard {
    padding: var(--sSpasingMedium);
    background-color: var(--cShade);
    border-radius: var(--radius);
}

.newMenuDishCard__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: calc(-1 * var(--sSpasingSmall));
    width: 100%;
}

.newMenuDishCard__headerWing {
    display: flex;
    align-items: center;
    margin-bottom: var(--sSpasingSmall);
}

.newMenuDishCard__headerWing:last-child {
    flex-grow: 1;
    justify-content: flex-end;
}

.newMenuDishCard__icon {
    margin-right: var(--sSpasingSmall);
}

.newMenuDishCard__icon svg {
    display: block;
}

.newMenuDishCard__priceInput {
    width: 100px;
}

.newMenuDishCard__body {
    margin-top: var(--sSpasingMedium);
}

.newMenuDishCard__dishGrid {
    display: grid;
    grid-gap: var(--sSpasingSmall);
}

.newMenuDishCard__complexCategory:not(:last-child) {
    margin-bottom: var(--sSpasingMedium);
}

.newMenuDishCard__complexCategoryTitle {
    margin-bottom: var(--sSpasingMedium);
    color: var(--cTextLight);
    font-size: var(--sFontSmall);
}

.newMenuDishCard__dishCard {
    padding: var(--sSpasingSmall);
    background-color: var(--cShade);
    border-radius: var(--radius);
}

.newMenuDishCard__dishCardRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: calc(-1 * var(--sSpasingSmall));
}

.newMenuDishCard__dishCardWing {
    display: flex;
    align-items: center;
    margin-bottom: var(--sSpasingSmall);
}

.newMenuDishCard__dishCardWing:last-child {
    flex-grow: 1;
    justify-content: flex-end;
}
