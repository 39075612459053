.multifield {
    border: none;
    padding: 0;
}

.multifield__legend {
    color: var(--cTextLight);
    font-size: var(--sFontSmall);
    margin-bottom: var(--sSpasingSmall);
}

.multifield__grid {
    display: grid;
    grid-gap: var(--sSpasingSmall);
    border-radius: var(--radiusSmall);
    transition-duration: var(--tGeneric);
    transition-timing-function: var(--tfStandart);
}

.multifield_errors .multifield__grid {
    box-shadow: 0 0 0 2px var(--cDanger);
}

.multifield_errors .multifield__grid:focus-within {
    box-shadow: none;
}
