.publicHeader {
    display: grid;
    grid-template-columns: 1fr auto;
    padding-inline: var(--sSpasingLarge);
    padding-block: var(--sSpasingTiny);
    align-items: center;
}

.publicHeader__logo {
    height: 40px;
}
