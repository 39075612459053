.errorContainer {
    padding: var(--sSpasingSmall);
    padding-bottom: 0;
    /*background-color: var(--cDanger);*/
    color: var(--cDanger);
    /*border-bottom-right-radius: var(--radiusSmall);*/
    /*border-bottom-left-radius: var(--radiusSmall);*/
    font-size: var(--sFontSmall);
}

.errorContainer p {
    padding: 0;
    margin: 0;
}

.errorContainer ul {
    margin: 0;
}
