.dishImageModal__image {
    margin-bottom: var(--sSpasingMedium);
    border-radius: var(--radius);
    overflow: hidden;
    position: relative;
}

.dishImageModal__image img {
    width: 100%;
    display: block;
}

.dishImageModal__imageAction {
    position: absolute;
    top: var(--sSpasingSmall);
    right: var(--sSpasingSmall);
}
