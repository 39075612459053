.headerDropdown {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.headerDropdown__dropdownWrapper {
    position: absolute;
    top: 100%;
    min-width: 100%;
    right: 0;
    padding-top: 6px;
    transition-duration: var(--tGeneric);
    transition-timing-function: var(--tfStandart);
    opacity: 0;
    visibility: hidden;
    transform-origin: center top;
    transform: translateY(-7px) scale(0.98);
    z-index: var(--zAbove);
}

.headerDropdown:hover .headerDropdown__dropdownWrapper,
.headerDropdown > *:focus + .headerDropdown__dropdownWrapper {
    opacity: 1;
    visibility: visible;
    transform: none;
}

.headerDropdown:focus-within .headerDropdown__dropdownWrapper {
    opacity: 1;
    visibility: visible;
    transform: none;
}

.headerDropdown__dropdown {
    margin: 0;
    list-style: none;
    padding: 10px 0;
    border-radius: var(--radius);
    background-color: var(--cMainBg);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.headerDropdown__dropdownItem {
    display: block;
    padding: 10px 15px;
    box-sizing: border-box;
    width: 100%;
    text-align: inherit;
    background-color: transparent;
    border: none;
    font-size: var(--sFontSmall);
    text-decoration: none;
    cursor: pointer;
    transition-duration: var(--tGeneric);
    transition-timing-function: var(--tfStandart);
    outline: none;
    white-space: nowrap;
}

.headerDropdown__dropdownItem:global(.focus-visible),
.headerDropdown__dropdownItem:hover {
    background-color: var(--cShade);
}

.headerDropdown__dropdownItem:global(.focus-visible) {
    box-shadow: var(--focusRing);
}
