.cartRibbon {
    position: sticky;
    bottom: 0;
    padding: var(--sSpasingSmall) 20px;
    background-color: var(--cThemeLight);
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition-duration: var(--tGeneric);
    transition-timing-function: var(--tfStandart);
    transform: translateY(100%);
    color: var(--cTextInverse);
}

.cartRibbon_open {
    transform: none;
}

@media screen and (min-width: 992px) {
    .cartRibbon {
        display: none;
    }
}
