.nav {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    background-color: var(--cMainBg);
    transition-duration: var(--tGeneric);
    transition-timing-function: var(--tfStandart);
    z-index: 201;
}

@media (max-width: 767px) {
    .nav {
        position: fixed;
        top: 0;
        left: 0;
    }

    .nav_closed {
        transform: translate(-100%);
    }
}

.nav__body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.nav__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    background-color: var(--cMainBg);
    padding-right: var(--sSpasingSmall);
    padding-left: var(--sSpasingSmall);
}

@media (min-width: 768px) {
    .nav__header {
        display: none;
    }
}

.nav__content {
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
}

.nav__curtain {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition-duration: var(--tGeneric);
    transition-timing-function: var(--tfStandart);
    visibility: hidden;
    opacity: 0;
    z-index: var(--zAbove);
}

.nav:not(.nav_closed) + .nav__curtain {
    opacity: 1;
    visibility: visible;
}

@media (min-width: 768px) {
    .nav__curtain {
        display: none;
    }
}

.nav__grid {
    padding: 15px 15px 15px 0;
    display: grid;
    grid-gap: 10px;
}

@media (max-width: 767px) {
    .nav__grid {
        padding-top: 0;
    }
}

.nav__reviews {
    padding-left: 15px;
    padding-right: 15px;
}

@media (min-width: 768px) {
    .nav__reviews {
        display: none;
    }
}

.nav__group {
    margin-bottom: 10px;
}

.nav__groupTitle {
    margin-left: 15px;
    margin-bottom: 10px;
    font-size: var(--sFontSmall);
    font-weight: bold;
}

.nav__groupContent {
    display: grid;
    grid-gap: 10px;
}

.nav__button {
    padding: 10px 25px 10px 25px;
    display: flex;
    align-items: center;
    text-decoration: none;
    border-radius: 40px;
    transition-duration: var(--tGeneric);
    transition-timing-function: var(--tfStandart);
    position: relative;
    font-size: var(--sFontSmall);
}

.nav__button > * {
    position: relative;
}

.nav__button:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 15px;
    right: 10px;
    height: 100%;
    width: auto;
    border-radius: 40px;
    transition-duration: var(--tGeneric);
    transition-timing-function: var(--tfStandart);
}

.nav__button:hover:before,
.nav__button:global(.focus-visible):before {
    background-color: var(--cShade);
    border-radius: var(--radius);
}

.nav__button:active:before {
    transition-duration: calc(var(--tGeneric) / 4);
    left: 17px;
    right: 12px;
}

.nav__button:global(.focus-visible):before {
    box-shadow: 0 0 0 3px var(--cThemeLight);
}

.nav__button.nav__button_active:before {
    background-color: var(--cThemeLight);
    border-radius: var(--radius);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    left: 0;
    right: 0;
}

.nav__button.nav__button_active {
    color: var(--cTextInverse);
}

.nav__button.nav__button_active:global(.focus-visible):before {
    box-shadow: 0 0 0 3px var(--cShade);
}

.nav__buttonIcon {
    margin-right: 15px;
}

.nav__buttonIcon svg {
    fill: currentColor;
    display: block;
}

.nav__lang {
    padding-left: 15px;
    padding-right: 15px;
    font-size: var(--sFontSmall);
    padding-bottom: 15px;
}

@media (min-width: 768px) {
    .nav__lang {
        display: none;
    }
}

.nav__footer {
    padding-left: 15px;
    padding-right: 15px;
    font-size: var(--sFontSmall);
    padding-bottom: 15px;
}

.nav__footerLogos {
    display: flex;
    list-style: none;
    /*justify-content: space-evenly;*/
    padding: 0;
    margin: 0;
}
