.publicCard {
    min-height: 100%;
    background-image: url('../../../images/login-bg.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.publicCard__wrapper {
    min-height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
}

.publicCard__container {
    max-width: 1000px;
    padding: var(--sSpasingLarge);
    box-sizing: border-box;
    width: 100%;
}

.publicCard__title {
    font-size: var(--sFontBig);
    font-weight: inherit;
    margin: 0;
}

.publicCard__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.publicCard__header:not(:last-child) {
    margin: 0 0 20px;
}

.publicCard__card {
    padding: 30px;
    background-color: var(--cMainBg);
    width: 100%;
    max-width: 400px;
    border-radius: var(--radius);
    box-sizing: border-box;
}

.publicCard_wide .publicCard__card {
    max-width: 640px;
}

@media (max-width: 991px) {
    .publicCard__card {
        margin: auto;
    }
}
