.dishCard {
    /*padding: 0 var(--sSpasingMedium);*/
    background-color: var(--cShade);
    border-radius: var(--radius);
    transition-duration: var(--tGeneric);
    transition-timing-function: var(--tfStandart);
    transition-property: background-color;
    cursor: pointer;
}

.dishCard_display {
    cursor: auto;
}

.dishCard:hover:not(.dishCard_display) {
    background: var(--cShadeActive);
}

.dishCard:focus-within:not(.dishCard_display) {
    background: var(--cShadeActive);
}

.dishCard_open.dishCard_complex {
    cursor: auto;
    background-color: var(--cShade);
    padding-bottom: var(--sSpasingMedium);
}

.dishCard__preview {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.dishCard__titleGroup {
    display: flex;
    align-items: center;
}

.dishCard__image {
    padding-bottom: 0;
    position: relative;
    transition-duration: 300ms;
    transition-timing-function: var(--tfStandart);
    overflow: hidden;
}

.dishCard_showImage .dishCard__image {
    padding-bottom: 75%;
}

.dishCard__imageWrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: var(--sSpasingSmall);
}

.dishCard__imageContainer {
    height: 100%;
    width: 100%;
    position: relative;
    border-radius: var(--radiusSmall);
    overflow: hidden;
    background-color: var(--cShadeActive);
    transition-duration: 300ms;
    transition-timing-function: var(--tfStandart);
    opacity: 0;
}

.dishCard_showImage .dishCard__imageContainer {
    transform: none;
    opacity: 1;
}

.dishCard:hover .dishCard__imageContainer {
    background-color: var(--cShadeRoot);
}

.dishCard:focus-within .dishCard__imageContainer {
    background-color: var(--cShadeRoot);
}

.dishCard__imageContainer img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.dishCard__imageClose {
    position: absolute;
    top: var(--sSpasingSmall);
    right: var(--sSpasingSmall);
}

.dishCard__imageButton {
    background: none;
    display: block;
    outline: none;
    border: none;
    cursor: pointer;
    transition-duration: var(--tGeneric);
    transition-timing-function: var(--tfStandart);
    padding: 0;
    height: 46px;
    width: 46px;
    flex-shrink: 0;
}

.dishCard_showImage .dishCard__imageButton {
    width: 0;
    opacity: 0;
}

.dishCard__imageButtonContainer {
    height: 100%;
    width: 100%;
    display: block;
    background-color: var(--cShadeActive);
    border-radius: var(--radius);
    transition-duration: var(--tGeneric);
    transition-timing-function: var(--tfStandart);
}

.dishCard__imageButtonContainer img {
    height: 100%;
    width: 100%;
    border-radius: var(--radius);
    /*opacity: 0;*/
}

.dishCard:focus-within .dishCard__imageButtonContainer {
    background-color: var(--cShadeRoot);
}

.dishCard__imageButton:hover .dishCard__imageButtonContainer,
.dishCard__imageButton:global(.focus-visible) .dishCard__imageButtonContainer {
    background-color: var(--cShadeRoot);
    transform: scale(0.85);
}

.dishCard__imageButton:global(.focus-visible) .dishCard__imageButtonContainer {
    box-shadow: var(--focusRing);
}

.dishCard__name {
    border: 0;
    font: inherit;
    background: none;
    outline: none;
    appearance: none;
    border-radius: var(--radiusSmall);
    padding: var(--sSpasingTiny);
    transition-duration: var(--tGeneric);
    transition-timing-function: var(--tfStandart);
    margin-left: var(--sSpasingSmall);
    cursor: inherit;
    text-align: inherit;
}

.dishCard__name:global(.focus-visible) {
    box-shadow: var(--focusRing);
}

.dishCard__price {
    white-space: nowrap;
    padding: var(--sSpasingSmall);
}

.dishCard__text {
    font-size: var(--sFontSmall);
}

.dishCard__groups {
    margin-bottom: var(--sSpasingLarge);
}

.dishCard__action {
    margin-top: calc(var(--sSpasingTiny));
    margin-right: calc(var(--sSpasingTiny));
    margin-bottom: calc(var(--sSpasingTiny));
    margin-left: var(--sSpasingMedium);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
}

.dishCard__contentActions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dishCard__content {
    padding: 0 var(--sSpasingMedium);
}
