.cartTotal {
    margin-bottom: var(--sSpasingLarge);
}

.cartTotal__row {
    display: flex;
    justify-content: space-between;
}

.cartTotal__row_bold {
    font-weight: bold;
    font-size: var(--sFontBig);
}

.cartTotal__row:not(:last-child) {
    border-bottom: 1px solid var(--cBorder);
    margin-bottom: var(--sSpasingSmall);
    padding-bottom: var(--sSpasingSmall);
}

.cartTotal__row_subtotal {
    font-weight: bold;
}

.cartTotal__discount {
    font-weight: bold;
    color: var(--cTheme);
}
