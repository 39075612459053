.locationGroup__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-left: calc(-1 * var(--sSpasingLarge));
    margin-bottom: calc(-1 * var(--sSpasingMedium));
}

.locationGroup__headerWing {
    margin-left: var(--sSpasingLarge);
    margin-bottom: var(--sSpasingMedium);
}

.locationGroup__headerGroup {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: calc(-1 * var(--sSpasingLarge));
    margin-bottom: calc(-1 * var(--sSpasingMedium));
}

.locationGroup__headerContent {
    margin-left: var(--sSpasingLarge);
    margin-bottom: var(--sSpasingMedium);
}

.locationGroup__headerCard {
    font-size: var(--sFontSmall);
    margin-left: var(--sSpasingLarge);
    margin-bottom: var(--sSpasingMedium);
}
