.searchableSelect {
    width: 320px;
}
.searchableSelect_grow {
    width: 100%;
}

.searchableSelect .searchableSelect__control {
    border-radius: var(--radius);
    background-color: var(--cShade);
    /*padding: var(--sSpasingMedium)*/
    /*    calc(var(--sSpasingLarge) + var(--sSpasingMedium)) var(--sSpasingMedium)*/
    /*    var(--sSpasingLarge);*/
    border: none;
    outline: none;
    cursor: pointer;
    transition-duration: var(--tGeneric);
    transition-timing-function: var(--tfStandart);
    width: 100%;
    box-sizing: border-box;
    font-size: inherit;
    appearance: none;
}

.searchableSelect .searchableSelect__control--is-focused {
    box-shadow: var(--focusRing);
}

.searchableSelect .searchableSelect__value-container {
    padding: var(--sSpasingMedium) var(--sSpasingMedium) var(--sSpasingMedium)
        var(--sSpasingLarge);
}

.searchableSelect .searchableSelect__value-container > div:last-child {
    padding: 0;
    margin: 0;
}

.searchableSelect .searchableSelect__indicator {
    padding: var(--sSpasingMedium);
}

.searchableSelect .searchableSelect__menu {
    z-index: var(--zAbove);
}
