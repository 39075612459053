
.newMenu__row {
    display: grid;
    grid-gap: var(--sSpasingMedium);
    grid-template-columns: 1fr 1fr;
    margin-bottom: var(--sSpasingLarge);
    align-items: center;
}

.newMenu__field {
    grid-column: span 2;
}

.newMenu__field_small {
    grid-column: span 1;
}

.newMenu__field_action {
    justify-self: flex-end;
}
