.lapDishes {
    margin-bottom: 40px;
}

.lapDishes_noMargin {
    margin-bottom: 0;
}

.lapDishes__grid {
    display: grid;
    grid-gap: var(--sSpasingMedium);
}

@media (min-width: 768px) {
    .lapDishes__grid {
        grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    }
}

.lapDishes__subgrid {
    display: grid;
    grid-gap: var(--sSpasingSmall);
}

.lapDishes__card {
    writing-mode: horizontal-tb;
    background-color: var(--cShade);
    border-radius: var(--radiusSmall);
    padding-top: var(--sSpasingTiny);
    padding-bottom: var(--sSpasingTiny);
}

.lapDishes__item {
    display: flex;
    justify-content: space-between;
    padding: 1px var(--sSpasingSmall);
}

.lapDishes__item:not(:last-child) {
    border-bottom: 1px solid var(--border);
}

.lapDishes__item > * + * {
    margin-left: var(--sSpasingSmall);
    font-weight: bold;
}
