.actionGroup {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: calc(var(--sSpasingSmall) / -2);
}

.actionGroup_center {
    justify-content: center;
}

.actionGroup_bottom {
    align-items: flex-end;
}

.actionGroup > * {
    margin: calc(var(--sSpasingSmall) / 2);
}

.actionGroup__item_grow {
    flex-grow: 1;
}
