.button {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 12px 20px;
    border: none;
    background: none;
    color: var(--cTextInverse);
    border-radius: var(--radius);
    font-weight: 500;
    outline: none;
    font-size: inherit;
    transition-duration: var(--tGeneric);
    transition-timing-function: var(--tfStandart);
    cursor: pointer;
    text-decoration: none;
    --cLoaderColor: var(--cShade);
}

.button__content {
    display: flex;
    align-items: center;
}

.button:before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: auto;
    background-color: var(--cThemeLight);
    opacity: 1;
    transition-duration: var(--tGeneric);
    transition-timing-function: var(--tfStandart);
    border-radius: var(--radius);
}

.button:disabled,
.button.button_disabled {
    cursor: not-allowed;
    color: var(--cTextLight);
}

.button:disabled:before,
.button.button_disabled:before {
    background-color: var(--cShade);
}

.button:not(:disabled):not(.button_disabled):hover:before,
.button:not(:disabled):not(.button_disabled):global(.focus-visible):before {
    background-color: var(--cThemeLightActive);
}

.button:not(:disabled):not(.button_disabled):global(.focus-visible):before {
    box-shadow: 0 0 0 3px var(--cTheme);
}

.button:not(:disabled):not(.button_disabled):active:before {
    transition-duration: calc(var(--tGeneric) / 4);
    background-color: var(--cThemeLight);
    left: 2px;
    right: 2px;
}

.button__content > * {
    position: relative;
}

.button__icon:not(:last-child) {
    margin-right: 10px;
}

.button__icon svg {
    display: block;
    fill: currentColor;
}

.button_iconOnly {
    padding-right: 12px;
    padding-left: 12px;
}

.button_small {
    font-size: var(--sFontSmall);
    padding: 10px var(--sSpasingMedium);
}

.button_small.button_iconOnly {
    padding-right: 10px;
    padding-left: 10px;
}

/* Secondary */

.button_secondary {
    background: none;
    color: inherit;
    border-radius: var(--radius);
    --cLoaderColor: var(--cThemeLight);
}

.button_secondary:disabled .button__icon,
.button_secondary.button_disabled .button__icon {
    opacity: 0.3;
}

.button_secondary:before {
    background-color: var(--cMainBg);
    opacity: 0;
    border-radius: 40px;
}

.button_secondary:not(:disabled):not(.button_disabled):hover,
.button_secondary:not(:disabled):not(.button_disabled):global(.focus-visible) {
    background: none;
}

.button_secondary:not(:disabled):not(.button_disabled):active {
    background: none;
}

.button_secondary:not(:disabled):not(.button_disabled):active:before {
    background: var(--cShadeActive);
}

.button_secondary:not(:disabled):not(.button_disabled):hover:before,
.button_secondary:not(:disabled):not(.button_disabled):global(.focus-visible):before {
    opacity: 1;
    border-radius: var(--radius);
    background-color: var(--cShade);
}

.button_secondary:not(:disabled):not(.button_disabled):global(.focus-visible):before {
    box-shadow: 0 0 0 3px var(--cThemeLight);
}

/* Secondary + Danger */

.button_secondary.button_danger {
    color: var(--cDanger);
    font-weight: bold;
    --cLoaderColor: var(--cDanger);
}

.button_secondary:not(:disabled):not(.button_disabled):global(.focus-visible):before {
    box-shadow: var(--focusRing);
}

/* Inverse */

.button_inverse .button__icon svg {
    fill: var(--cMainBg);
}

.button_inverse::before {
    background-color: var(--cShadeTransparent);
}

.button_inverse:not(:disabled):not(.button_disabled):hover:before,
.button_inverse:not(:disabled):not(.button_disabled):global(.focus-visible):before {
    background-color: var(--cShadeTransparent);
}

.button__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 0;
    visibility: hidden;
    transition-duration: var(--tGeneric);
    transition-timing-function: var(--tfStandart);
}

.button_loading .button__loader {
    transform: translate(-50%, -50%) scale(0.8);
    opacity: 1;
    visibility: visible;
}

.button_iconOnly .button__loader {
    transform: translate(-50%, -50%) scale(0.3);
}

.button_iconOnly.button_loading .button__loader {
    transform: translate(-50%, -50%) scale(0.5);
}

.button_loading .button__content {
    opacity: 0.2;
}

.button_loading:not(.button_secondary):disabled:before,
.button_loading:not(.button_secondary).button_disabled:before {
    background-color: var(--cThemeLight);
}

.button_loading.button_secondary:disabled:before,
.button_loading.button_secondary.button_disabled:before {
    opacity: 1;
    border-radius: var(--radius);
}
