.orderCardItem:not(.orderCardItem_stripped) {
    background-color: var(--cShade);
    border-radius: var(--radius);
    padding: var(--sSpasingSmall);
}

.orderCardItem__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: var(--sSpasingMedium);
    margin-bottom: var(--sSpasingSmall);
    margin-top: var(--sSpasingSmall);
}

.orderCardItem__quantity {
}

.orderCardItem__quantity_accent {
    color: var(--cDanger);
    font-weight: bold;
}

.orderCardItem_stripped .orderCardItem__header {
    margin: 0;
    align-items: baseline;
}

.orderCardItem__headerPrice {
    margin-left: var(--sSpasingSmall);
    padding-left: var(--sSpasingSmall);
    border-left: 1px solid var(--cBorder);
}

.orderCardItem__list {
    list-style: none;
    padding: 0;
    margin: var(--sSpasingSmall) 0 var(--sSpasingSmall) var(--sSpasingMedium);
    font-size: var(--sFontSmall);
}

.orderCardItem__listItem {
    display: flex;
    justify-content: space-between;
}

.orderCardItem__listItem:not(:last-child) {
    margin-bottom: 4px;
}

.orderCardItem__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.orderCardItem__counter {
    margin-left: var(--sSpasingMedium);
}

.orderCardItem__content {
    display: flex;
    align-items: center;
    font-size: var(--sFontSmall);
}

.orderCardItem__remove {
    margin-left: calc(-1 * var(--sSpasingMedium));
    margin-top: calc(-1 * var(--sSpasingMedium));
    margin-right: var(--sSpasingMedium);
}
