.languageSwitcher {
    list-style: none;
    padding: 0;
    display: flex;
    margin: 0 -3px;
}

.languageSwitcher__button {
    background: none;
    font-size: inherit;
    padding: 4px 7px 3px;
    margin: 0 3px;
    border-radius: var(--radiusSmall);
    border: 1px solid transparent;
    cursor: pointer;
    outline: none;
    text-transform: uppercase;
    transition-duration: var(--tGeneric);
    transition-timing-function: var(--tfStandart);
}

.languageSwitcher__button:hover,
.languageSwitcher__button:global(.focus-visible) {
    background-color: var(--cShade);
    border-color: transparent;
}

.languageSwitcher__button_active {
    border-color: var(--border);
}
