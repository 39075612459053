.menuComplexGroup:not(:last-child) {
    margin-bottom: var(--sSpasingLarge);
}

.menuComplexGroup__title {
    margin-bottom: var(--sSpasingSmall);
    color: var(--cTextLight);
    font-size: var(--sFontSmall);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menuComplexGroup__required {
    display: flex;
    padding: var(--sSpasingTiny) var(--sSpasingSmall) var(--sSpasingTiny)
        var(--sSpasingTiny);
    margin-left: var(--sSpasingSmall);
    background-color: var(--cThemeLight);
    border-radius: var(--radiusSmall);
    color: var(--cTextInverse);
}
.menuComplexGroup__required svg {
    margin-right: var(--sSpasingTiny);
    fill: currentColor;
}

.menuComplexGroup__itemGrid {
    display: grid;
    grid-gap: var(--sSpasingSmall);
}

.menuComplexGroup__item {
    position: relative;
}

.menuComplexGroup__item input {
    position: absolute;
    cursor: pointer;
    right: var(--sSpasingSmall);
    top: var(--sSpasingSmall);
    opacity: 0;
}

.menuComplexGroup__itemLabel {
    display: flex;
    align-items: center;
    padding: var(--sSpasingSmall);
    padding-left: var(--sSpasingMedium);
    background-color: var(--cShade);
    justify-content: space-between;
    border-radius: var(--radius);
    cursor: pointer;
    transition-duration: var(--tGeneric);
    transition-timing-function: var(--tfStandart);
}

.menuComplexGroup__itemLabel_display {
    cursor: auto;
}

.menuComplexGroup__itemActions {
    display: flex;
    align-items: center;
    margin-top: calc(-0.5 * var(--sSpasingSmall));
    margin-bottom: calc(-0.5 * var(--sSpasingSmall));
}

.menuComplexGroup__itemTick {
    flex-shrink: 0;
    display: block;
    height: 22px;
    width: 22px;
    margin-right: var(--sSpasingSmall);
    margin-left: var(--sSpasingSmall);
    border-radius: 50%;
    border: 1px solid var(--cBorder);
    box-sizing: border-box;
    transition-duration: calc(var(--tGeneric) / 2);
    transition-timing-function: var(--tfStandart);
    position: relative;
    background-color: var(--cMainBg);
    color: var(--cTextInverse);
}

.menuComplexGroup_optional .menuComplexGroup__itemTick {
    border-radius: var(--radiusSmall);
}

.menuComplexGroup__itemTick svg {
    position: absolute;
    top: 1px;
    left: 1px;
    opacity: 0;
    transform: scale(0.8);
    transition-duration: calc(var(--tGeneric) / 2);
    transition-timing-function: var(--tfStandart);
    fill: currentColor;
}

.menuComplexGroup__item
    input:checked
    + .menuComplexGroup__itemLabel
    .menuComplexGroup__itemTick {
    background-color: var(--cThemeLight);
    border-color: var(--cThemeLight);
}
.menuComplexGroup__item
    input:global(.focus-visible)
    + .menuComplexGroup__itemLabel {
    box-shadow: var(--focusRing);
}

.menuComplexGroup__item
    input:checked
    + .menuComplexGroup__itemLabel
    .menuComplexGroup__itemTick
    svg {
    opacity: 1;
    transform: none;
}
