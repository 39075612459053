.loginForm {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--sSpasingLarge);
}

.loginForm > * {
    grid-column: span 2;
}

.loginForm__action {
    grid-column: span 1;
    margin-top: var(--sSpasingMedium);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.loginForm__link {
    font-size: var(--sFontSmall);
}
