.dishesByCategory__categoryBlock {
}

.dishesByCategory__categoryTitle {
    margin-bottom: var(--sSpasingMedium);
    font-weight: bold;
}

.dishesByCategory__categoryGrid {
    display: grid;
    grid-gap: var(--sSpasingSmall);
    margin-bottom: var(--sSpasingLarge);
}
