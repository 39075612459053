.userCreateForm {
}

.userCreateForm__header {
    margin-top: 0;
    margin-bottom: var(--sSpasingLarge);
    font-size: var(--sFontBig);
}

.userCreateForm__header > * {
    display: block;
}

.userCreateForm__headerTitle {
    font-weight: normal;
    margin-bottom: var(--sSpasingSmall);
}

.userCreateForm__headerSeparator {
    font-size: var(--sFontBody);
    font-weight: normal;
    margin-right: var(--sSpasingTiny);
}
