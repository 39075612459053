.sumCard {
    display: flex;
    flex-direction: column;
    margin: 0;
    font-size: var(--sFontSmall);
}

.sumCard__item {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin: 3px 0;
}

.sumCard__item:first-child {
    margin-top: 0;
}

.sumCard__item:last-child {
    margin-bottom: 0;
}

.sumCard__item dd,
.sumCard__item dt {
    margin: 0;
}

.sumCard__item dt {
    margin-right: var(--sSpasingSmall);
}
