.disclaimer {
    color: var(--cTextLight);
    font-size: var(--sFontSmall);
}

.disclaimer a {
    color: inherit;
}

.disclaimer_warning {
    color: var(--cDanger);
    font-weight: bold;
}
