.radioGroup {
    border: none;
    padding: 0;
    margin: 0;
}

.radioGroup__legend {
    color: var(--cTextLight);
    font-size: var(--sFontSmall);
    margin-bottom: var(--sSpasingSmall);
}

.radioGroup__grid {
    display: grid;
    grid-gap: var(--sSpasingSmall);
}
