.contentGrid {
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 20px;
    max-width: 1440px;
    margin: auto;
}

@media (max-width: 460px) {
    .contentGrid {
        padding: var(--sSpasingMedium);
    }
}

.contentGrid__block {
    grid-column: var(--columnSpan, span 12);
}

@media (max-width: 991px) {
    .contentGrid__block {
        grid-column: span 12;
    }
}
