.dishBalanceCard {
    padding: var(--sSpasingMedium);
    background-color: var(--cShade);
    border-radius: var(--radius);
}

.dishBalanceCard__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dishBalanceCard__actions {
    margin-left: var(--sSpasingMedium);
    flex-shrink: 0;
}

.dishBalanceCard__input {
    width: 100px;
}
