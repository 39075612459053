.lapLocations {
}

.lapLocations__location {
    padding: var(--sSpasingMedium);
    border-radius: var(--radiusSmall);
    background-color: var(--cShade);
    margin-bottom: var(--sSpasingMedium);
}

.lapLocations__header {
    margin-bottom: var(--sSpasingMedium);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.lapLocations__headerContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.lapLocations__headerContent > * {
    height: auto;
}

.lapLocations__footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.lapLocations__content{
    margin-bottom: var(--sSpasingLarge);
}

.lapLocations__headerTitle {
    margin-right: var(--sSpasingMedium);
}
