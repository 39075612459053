.contentCard {
    --cardPadding: var(--sSpasingLarge);
    padding: var(--cardPadding);
    background-color: var(--cMainBg);
    border-radius: var(--radius);
    height: 100%;
    box-sizing: border-box;
    position: relative;
}

.contentCard:not(.contentCard_header) .contentGrid__title {
    font-size: var(--sFontBig);
    margin: 0;
}

.contentCard_accent {
    background-color: var(--cThemeLight);
    color: var(--cTextInverse);
}

.contentCard_tinyPadding {
    --cardPadding: var(--sSpasingSmall);
}

.contentCard_smallPadding {
    --cardPadding: var(--sSpasingMedium);
}

.contentCard_center .contentCard__title {
    text-align: center;
}

.contentCard__wrapper {
    position: relative;
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.contentCard__titleWrapper {
    margin-bottom: var(--sSpasingMedium);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contentCard_fixedHeight .contentCard__wrapper {
    height: 100%;
}

.contentGrid__content {
    flex-grow: 1;
}

.contentCard_fixedContentHeight .contentGrid__content {
    height: 100%;
}

