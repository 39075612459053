.categoryMenu {
    margin-bottom: var(--sSpasingSmall);
}

.categoryMenu_expanded {
    margin-bottom: var(--sSpasingLarge);
}

.categoryMenu__title {
    font-weight: bold;
    width: calc(100% + (2 * var(--sSpasingMedium)));
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--sSpasingMedium);
    margin-inline: calc(-1 * var(--sSpasingMedium));
    border: none;
    border-radius: var(--radiusSmall);
    background-color: transparent;
    cursor: pointer;
    transition-duration: var(--tGeneric);
    transition-timing-function: var(--tfStandart);
}

.categoryMenu__title:hover,
.categoryMenu__title:global(.focus-visible) {
    background-color: var(--cShade);
}

.categoryMenu__title:global(.focus-visible) {
    box-shadow: var(--focusRing);
    outline: none;
}

.categoryMenu__grid {
    margin-top: var(--sSpasingSmall);
    display: grid;
    grid-gap: var(--sSpasingSmall);
}
