.lapEditor {
    padding: var(--sSpasingMedium);
    margin-bottom: var(--sSpasingLarge);
    background-color: var(--cShade);
    border-radius: var(--radiusSmall);
}

.lapEditor__grid {
    display: grid;
    gap: var(--sSpasingSmall);
    margin-bottom: var(--sSpasingLarge);
}

.lapEditor__item {
    border-radius: var(--radiusSmall);
    background-color: var(--cShade);
    padding: var(--sSpasingSmall) var(--sSpasingMedium);
    margin-left: 40px;
    position: relative;
    display: flex;
    align-items: center;
}

.lapEditor__item > * {
    --cShade: var(--cShadeRoot);
}

.lapEditor__item::before {
    position: absolute;
    content: '';
    display: block;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: var(--cText);
    top: 50%;
    left: -25px;
    margin-left: -4px;
    margin-top: -4px;
}

.lapEditor__itemLabel {
    flex-grow: 1;
}
