.table__wrapper {
    overflow: auto;
    max-width: 100%;
    display: flex;
}

.table__content {
    border: 1px solid var(--cBorder);
    border-radius: var(--radius);
    flex-grow: 1;
}

.table {
    min-width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

.table thead tr {
    border-bottom: 1px solid var(--cBorder);
}

.table th {
    padding: var(--sSpasingMedium) var(--sSpasingSmall);
    text-align: inherit;
    white-space: nowrap;
}

.table th:first-child {
    padding-left: var(--sSpasingLarge);
}

.table th:last-child {
    padding-right: var(--sSpasingLarge);
}

.table th:last-child:not(:first-child) {
    text-align: right;
}

.table__item {
    transition-duration: var(--tGeneric);
    transition-timing-function: var(--tfStandart);
}

.table__item:hover {
    background-color: var(--cShadeTransparent);
}

.table__item:not(:first-child) td {
    border-top: 1px solid var(--cBorder);
}

.table__item td {
    padding: var(--sSpasingSmall);
}

.table__item td:first-child {
    padding-left: var(--sSpasingLarge);
}

.table__item td:last-child {
    padding-right: var(--sSpasingSmall);
}

.table__controls {
    display: flex;
    justify-content: flex-end;
}

.table__placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: var(--sSpasingLarge);
    padding-bottom: var(--sSpasingLarge);
}

.table__placeholderTitle {
    font-size: var(--sFontH3);
    margin-bottom: var(--sSpasingMedium);
}

.table__sortingButton {
    padding: 0;
    font-size: inherit;
    border: none;
    background: none;
    outline: none;
    display: flex;
    font-weight: inherit;
    min-height: 18px;
    cursor: pointer;
}

.table__sortingButton:focus-visible {
    text-decoration: underline;
}

.table__sortingButtonLabel {
    display: block;
    white-space: nowrap;
}

.table__sortingButtonIcon {
    /*margin-left: var(--sSpasingTiny);*/
    margin-right: var(--sSpasingTiny);
}

.table__sortingButtonIcon svg {
    display: block;
}
