.thinLoader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: none;
    transition-duration: var(--tGeneric);
    transition-timing-function: var(--tfStandart);
}

.thinLoader_card {
    border-radius: var(--radius);
}

.thinLoader_active {
    display: block;
}

.thinLoader::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    height: 3px;
    width: auto;
    right: 100%;
    background-color: var(--cThemeLight);
    animation: springRight 2s infinite cubic-bezier(0.2, 0, 0.5, 1),
        springLeft 2s infinite cubic-bezier(0.5, 0, 0.8, 1);
}

@keyframes springRight {
    0% {
        right: 100%;
    }
    60% {
        right: 0;
    }
    100% {
        right: 0;
    }
}

@keyframes springLeft {
    0% {
        left: 0;
    }
    20% {
        left: 0;
    }
    80% {
        left: 100%;
    }
    100% {
        left: 100%;
    }
}
