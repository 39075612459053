.fileInput {
    width: 100%;
    position: relative;
}

.fileInput__input {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.fileInput__label {
    cursor: pointer;
    padding: var(--sSpasingMedium);
    border-radius: var(--radiusSmall);
    background-color: var(--cShade);
    width: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    transition-duration: var(--tGeneric);
    transition-timing-function: var(--tfStandart);
    min-height: 50px;
}

.fileInput__input:global(.focus-visible) + .fileInput__label {
    box-shadow: var(--focusRing);
}

.fileInput__label:hover {
    background-color: var(--cShadeActive);
}

.fileInput__remove {
    position: absolute;
    right: var(--sSpasingTiny);
    top: var(--sSpasingTiny);
}
