.dishSelector {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.dishSelector__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: var(--sSpasingMedium);
    flex-basis: 0;
    flex-grow: 1;
    overflow: auto;
    grid-auto-rows: min-content;
    padding: var(--sSpasingTiny);
    margin: calc(-1 * var(--sSpasingTiny));
    margin-top: var(--sSpasingLarge);
}

@media (max-width: 991px) {
    .dishSelector__grid {
        flex-basis: initial;
    }
}

.dishSelector__filter {
    display: grid;
    grid-gap: var(--sSpasingSmall);
}

.dishSelector__item {
    padding: var(--sSpasingMedium);
    border-radius: var(--radius);
    background-color: var(--cShade);
    text-align: inherit;
    font: inherit;
    border: inherit;
    outline: none;
    transition-duration: var(--tGeneric);
    transition-timing-function: var(--tfStandart);
    cursor: pointer;
}

.dishSelector__item:hover,
.dishSelector__item:focus-visible {
    background-color: var(--cShadeActive);
}

.dishSelector__item_active {
    box-shadow: var(--focusRing);
}

.dishSelector__itemRow {
    display: flex;
    /*flex-wrap: wrap;*/
    margin-bottom: calc(-1 * var(--sSpasingSmall));
    height: calc(100% + var(--sSpasingSmall));
}

.dishSelector__itemContent {
    flex-grow: 1;
    margin-bottom: calc(var(--sSpasingSmall));
    display: flex;
    flex-direction: column;
}

.dishSelector__itemTitle {
}

.dishSelector__itemCategory {
    font-size: var(--sFontSmall);
    opacity: 0.6;
}

.dishSelector__itemPrice {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    align-self: flex-end;
    margin: calc(-1 * var(--sSpasingTiny));
    margin-bottom: calc(var(--sSpasingSmall) - var(--sSpasingTiny));
    padding-left: var(--sSpasingMedium);
}
