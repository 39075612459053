html,
body {
    height: 100%;
    margin: 0;
    color: var(--cText);
}

#root {
    height: 100%;
}
