.pageTitle {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    margin-left: calc(-1 * var(--sSpasingLarge));
    margin-bottom: calc(-1 * var(--sSpasingMedium));
}

.pageTitle > * {
    margin-left: var(--sSpasingLarge);
    margin-bottom: var(--sSpasingMedium);
}
