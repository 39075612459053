.layout {
    display: grid;
    grid-template-areas: 'l h' 'n c';
    grid-template-columns: minmax(240px, max-content) auto;
    grid-template-rows: 60px auto;
    height: 100%;
    transition-duration: var(--tGeneric);
    transition-timing-function: var(--tfStandart);
}

@media (max-width: 767px) {
    .layout {
        grid-template-areas: 'h' 'c';
        grid-template-columns: 1fr;
        grid-template-rows: 48px auto;
    }
}

.layout__logoContainer {
    display: flex;
    align-items: center;
    padding-left: 15px;
    padding-block: 5px;
}

@media (max-width: 767px) {
    .layout__logoContainer {
        display: none;
    }
}

.layout__logo {
    display: block;
}

.layout__logo img {
    display: block;
}

.layout__header {
    grid-area: h;
}

.layout__nav {
    grid-area: n;
}

.layout__content {
    grid-area: c;
    background-color: var(--cShade);
    border-top-left-radius: var(--radius);
    overflow: auto;
    height: 100%;
    position: relative;
    transition-duration: var(--tGeneric);
    transition-timing-function: var(--tfStandart);
}

@media (max-width: 767px) {
    .layout__content {
        border-radius: 0;
    }
}
